<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Edit Latihan
    </h3>
    <v-card>
      <v-card-text class="pa-8">
        <div>
          <div class="mb-4">
            <h3>Edit Latihan</h3>
            <span>Latihan akan diperbarui ke dalam BAB</span>
          </div>

          <div>
            <v-text-field
              v-model="chapter.name"
              label="Judul"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <v-row>
            <v-col md="6">
              <v-menu
                v-model="deadline"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exercise.deadline"
                    label="Waktu Pengumpulan"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillDeadline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exercise.deadline"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="deadline = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <v-menu
                v-model="publish_at"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exercise.publish_at"
                    label="Waktu Penerbitan"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillPublishAt"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exercise.publish_at"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="publish_at = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div>
            <v-textarea
              v-model="exercise.instruction"
              label="Instruksi"
              outlined
              dense
            >
            </v-textarea>
          </div>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            color="primary"
            :loading="isLoadingButton"
            @click="update(chapter.uuid)"
          >
            Update
          </v-btn>
          <v-btn
            color="primary"
            outlined
            large
            @click="returnBack"
          >
            Batal
          </v-btn>
        </v-card-actions>
        <div class="mb-4">
          <div class="mb-4 mt-7">
            <h3 class="font-weight-bold black--text">
              Soal Pilihan Ganda
            </h3>
          </div>
          <v-card
            v-for="questionItem in exerciseMultiple"
            :key="questionItem.uuid"
            outlined
            class="mb-2"
          >
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="align-center">
                    <v-col md="2">
                      <span>Soal No {{ questionItem.number }}</span>
                    </v-col>
                    <v-col md="4">
                      <v-chip
                        outlined
                        class="v-chip-light-bg success--text"
                        h-e-a-d
                        @click="checkDescription(questionItem.name)"
                      >
                        Lihat Deskripsi
                      </v-chip>
                    </v-col>
                    <v-col md="4">
                      <span>A.{{ questionItem.choice_1 }}</span>
                    </v-col>
                    <v-col md="1">
                      <a
                        href=""
                        class="text-decoration-none"
                        @click.prevent="editQuestion(questionItem.uuid)"
                      >Edit</a>
                    </v-col>
                    <v-col md="1">
                      <a
                        href=""
                        class="text-decoration-none"
                        @click.prevent="confirmDestroy(questionItem.uuid)"
                      >Hapus</a>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Pembahasan</p>
                  {{ questionItem.discussion }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
        <div
          v-for="(quest, index) of questions"
          :key="quest.uuid"
        >
          <div>
            <div class="d-flex justify-space-between align-center mb-5">
              <div>
                <h4>
                  Soal Nomor {{ exerciseMultiple.length + (index+1) }}
                </h4>
              </div>
              <div>
                <v-btn
                  color="primary pa-2"
                  icon
                  small
                  @click="deleteExerciseQuestion(index)"
                >
                  <v-icon>
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="mb-4">
              <CKEditor
                :value="quest[index].name"
                label="Content"
                outlined
                dense
                @input="handleCKeditor($event, index)"
              ></CKEditor>
            </div>
          </div>
          <div class="mb-4">
            <h4 class="mb-4">
              Masukkan Gambar (jika ada)
            </h4>
            <FileInput
              :value="quest[index].image"
              label="Soal Bergambar"
              outlined
              dense
              :disabled="false"
              :prependicon="icons.mdiImageMultipleOutline"
              @input="getFile"
            ></FileInput>
          </div>
          <div>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <h4 class="mb-5 mt-8">
                  Pilihan
                </h4>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="quest[index].choice_a"
                        label="Jawaban"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          A.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="quest[index].choice_b"
                        label="Jawaban"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          C.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="quest[index].choice_c"
                        label="Jawaban"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          B.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="quest[index].choice_d"
                        label="Jawaban"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          D.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                class="py-0"
              >
                <h4 class="mb-4">
                  Jawaban Yang Benar
                </h4>
                <div class="">
                  <v-text-field
                    v-model="quest[index].answer"
                    label="Jawaban yang benar"
                    outlined
                    dense
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                class="py-0"
              >
                <h4 class="mb-4">
                  Point Jawaban Yang Benar
                </h4>
                <div>
                  <v-text-field
                    v-model="quest[index].point"
                    label="Point"
                    type="number"
                    outlined
                    dense
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <h4 class="mb-4">
                  Pembahasan
                </h4>
                <div>
                  <div>
                    <v-textarea
                      v-model="quest[index].discussion"
                      label="Pembahasan"
                      outlined
                      dense
                    >
                    </v-textarea>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="mb-6">
            <v-switch
              v-model="quest[index].archive"
              :label="` ${quest[index].archive?'Disimpan': 'Tidak Disimpan' } Ke Arsip `"
            ></v-switch>
          </div>
        </div>
        <div>
          <div
            v-show="isHide"
          >
            <v-card-actions class="d-flex justify-center mb-0">
              <v-btn
                color="primary"
                large
                :loading="isLoadingButton"
                :disabled="isDisabledButton"
                @click="addQuestion()"
              >
                Simpan
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                @click="deleteExerciseQuestion()"
              >
                Batal
              </v-btn>
            </v-card-actions>
          </div>
          <div class="mb-8 mt-8">
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="addExerciseQuestion"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> TAMBAH SOAL</a>
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="showFormArchive"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> AMBIL DARI ARSIP SOAL</a>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mb-8 mt-8">
          <div class="mb-4">
            <div class="mb-4">
              <h3 class="font-weight-bold black--text">
                Soal Essai
              </h3>
            </div>
            <v-card
              v-for="questionEssayItem in exerciseEssay"
              :key="questionEssayItem.uuid"
              class="mb-2"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="align-center">
                      <v-col md="2">
                        <span>Soal No {{ questionEssayItem.number }}</span>
                      </v-col>
                      <v-col md="4">
                        <v-chip
                          outlined
                          class="v-chip-light-bg success--text"
                          h-e-a-d
                          @click="checkDescription(questionEssayItem.name)"
                        >
                          Lihat Deskripsi
                        </v-chip>
                      </v-col>
                      <v-col md="4">
                        <span v-html="questionEssayItem.name"></span>
                      </v-col>

                      <v-col md="1">
                        <a
                          href=""
                          class="text-decoration-none"
                          @click.prevent="editQuestionEssay(questionEssayItem.uuid)"
                        >Edit</a>
                      </v-col>
                      <v-col md="1">
                        <a
                          href=""
                          class="text-decoration-none"
                          @click.prevent="confirmDestroy(questionEssayItem.uuid)"
                        >Hapus</a>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>Pembahasan</p>
                    {{ questionEssayItem.discussion }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
            <div
              v-for="(questEssay, index) of questionsEssay"
              :key="questEssay.uuid"
            >
              <div class="d-flex justify-space-between align-center mb-5">
                <div>
                  <h4>
                    Soal Nomor {{ exerciseEssay.length + (index+1) }}
                  </h4>
                </div>
                <div>
                  <v-btn
                    color="primary pa-2"
                    icon
                    small
                    @click="deleteExerciseEssay(index)"
                  >
                    <v-icon>
                      {{ icons.mdiCloseCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div>
                <v-text-field
                  v-model="questEssay[index].name"
                  label="Soal"
                  outlined
                  type="text"
                  dense
                >
                </v-text-field>
              </div>
              <div>
                <v-textarea
                  v-model="questionEssay[index].discussion"
                  label="Pembahasan"
                  outlined
                  dense
                >
                </v-textarea>
              </div>
              <div class="mb-4">
                <h4 class="mb-4">
                  Masukkan Gambar (jika ada)
                </h4><FileInput
                  :value="questEssay[index].image"
                  label="Soal Bergambar"
                  outlined
                  dense
                  :disabled="false"
                  :prependicon="icons.mdiImageMultipleOutline"
                  @input="getFileEssay"
                ></FileInput>
              </div>
              <div class="mb-6">
                <v-switch
                  v-model="questEssay[index].archive"
                  :label="` ${questEssay[index].archive?'Disimpan': 'Tidak Disimpan' } Ke Arsip `"
                ></v-switch>
              </div>
            </div>
            <div>
              <div
                v-show="isHideEssay"

                class="mt-10"
              >
                <v-card-actions class="d-flex justify-center mb-0">
                  <v-btn
                    color="primary"
                    large
                    :loading="isLoadingButton"
                    :disabled="isDisabledButton"
                    @click="addQuestionEssay()"
                  >
                    Simpan
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    large
                    @click="deleteExerciseEssay()"
                  >
                    Batal
                  </v-btn>
                </v-card-actions>
              </div>
              <div class="mb-8 mt-8">
                <a
                  class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
                  @click="addExerciseQuestionEssay"
                ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> TAMBAH SOAL</a>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      header="Edit Pertanyaan"
      text-button="Update"
      :is-loading-button="isLoadingButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
      @handler-button="type==='multipleChoice'? updateQuestion() : updateQuestionEssay()"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="questionEdit.number"
            label="Number"
            type="number"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div class="mb-4">
          <CKEditor
            :value="initialContent"
            label="Soal"
            outlined
            dense
            @input="handleCKeditor($event, 'edit')"
          ></CKEditor>
        </div>
        <div class="mb-4">
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <div>
            <FileInput
              :value="initialFile"
              label="Soal Bergambar"
              outlined
              dense
              :disabled="false"
              :prependicon="icons.mdiImageMultipleOutline"
              @input="getFile"
            ></FileInput>
          </div>
        </div>
        <div v-if="type =='multipleChoice'">
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-5 mt-8">
                Kunci Jawaban
              </h4>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_a"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        A.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_b"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        C.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_c"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        B.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_d"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        D.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Jawaban Yang Benar
              </h4>
              <div class="">
                <v-text-field
                  v-model="questionEdit.answer_key.answer"
                  label="Jawaban yang benar"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Point Jawaban Yang Benar
              </h4>
              <div>
                <v-text-field
                  v-model="questionEdit.answer_key.point"
                  label="Point"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-4">
                Pembahasan
              </h4>
              <div>
                <div>
                  <v-textarea
                    v-model="questionEdit.discussion"
                    label="Pembahasan"
                    outlined
                    dense
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="archiveModalDialog"
      header="Pilih Soal"
      text-button="Export"
      :width="800"
      @close-modal="archiveModalDialog = false"
      @close-button="archiveModalDialog = false"
      @handler-button="archiveExport"
    >
      <template v-slot:body>
        <span>Pilih soal dari Arsip Soal yang akan anda masukan kedalam soal latihan </span>
        <v-row class="mt-4">
          <v-col md="8">
            <div>
              <v-text-field
                v-model="search"
                label="Cari Soal"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="4">
            <div class="d-md-flex">
              <span class="mt-2 me-2">Kategori:</span>
              <div>
                <v-autocomplete
                  outlined
                  dense
                  label="Semua"
                ></v-autocomplete>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-simple-table
          class="mb-4 overflow-x-auto"
          height="400"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <v-checkbox
                    v-model="selectAll"
                  ></v-checkbox>
                </th>
                <th class="text-left">
                  Soal
                </th>
                <th class="text-left">
                  Kategori
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(archive, index) in filteredList"
                :key="index"
              >
                <td>
                  <v-checkbox
                    v-model="selected"
                    :value="archive.uuid"
                    @change="handleCheckboxChange(archive.uuid, selected)"
                  ></v-checkbox>
                </td>
                <td>
                  <div v-html="archive.name">
                  </div>
                </td>
                <td>{{ archive.is_essay === '1' ? "Essay" : "Pilihan Ganda" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card
          width="180"
          height="50"
          style="border: 1px dashed #C4C4C4;"
          class="d-flex align-center text-center pa-4"
        >
          Soal yang dipilih :  {{ totalArchiveQuestion ? totalArchiveQuestion : 0 }}
        </v-card>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-modal="confirmDialog = false"
      @close-button="confirmDialog = false"
      @destroy-button="destroyQuestion"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import CKEditor from '@/views/components/CkEditor.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import {
  mdiArrowLeft,
  mdiCloseCircleOutline,
  mdiFlaskEmptyPlusOutline,
  mdiImageMultipleOutline,
  mdiNoteOutline,
  mdiPlus,
} from '@mdi/js'

export default {
  name: 'ElearningExerciseEdit',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    CKEditor,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiArrowLeft,
        mdiNoteOutline,
        mdiFlaskEmptyPlusOutline,
        mdiPlus,
        mdiCloseCircleOutline,
        mdiImageMultipleOutline,
      },
      search: '',
      archiveModalDialog: false,
      totalArchiveQuestion: 0,
      isHide: false,
      isHideEssay: false,
      isLoadingButton: false,
      isDisabledButton: false,
      status: true,
      deadline: false,
      publish_at: false,
      chapter: {},
      chapterLength: 0,
      type_uuid: '',
      type: '',
      archiveQuestion: [],
      selected: [],
      dialog: '',
      exercise: {},
      questionImage: [],
      questionImageEssay: [],
      exerciseMultiple: [],
      exerciseEssay: [],
      questionItems: [],
      questions: [],
      question: [],
      questionEdit: {
        answer_key: {},
      },
      filtered: [],
      questionsEssay: [],
      questionEssayItems: [],
      questionEssay: [],
      answer: [],
      numberQuestion: 0,
      confirmDialog: false,
      modalDialog: false,
      width: 600,
      tempContent: '',
      tempImage: [],
      detail: '',
    }
  },
  computed: {
    filteredList() {
      return this.setArchive()
    },
    initialFile: {
      get() {
        if (typeof this.questionEdit.image === 'string') {
          return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        }

        return this.questionEdit.image
      },
      set() {
        return this.questionEdit.image
      },
    },
    initialContent: {
      get() {
        if (this.questionEdit.name === null) {
          return ''
        }

        return this.questionEdit.name
      },
      set() {
        return this.questionEdit.name
      },
    },
    selectAll: {
      get() {
        return this.totalArchiveQuestion ? this.selected.length === this.filtered.length : false
      },
      set(value) {
        const selected = []
        this.totalArchiveQuestion = 0
        if (value) {
          this.filtered.forEach(archive => {
            selected.push(archive.uuid)
            this.totalArchiveQuestion += 1
          })
        } else {
          this.selected = []
          this.totalArchiveQuestion = 0
        }

        this.selected = selected
      },
    },
  },
  mounted() {
    this.getChapter(this.$route.params.uuid)
  },
  methods: {
    checkDescription(data) {
      this.detail = data
      this.modalDescription = true
    },

    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      if (index !== 'edit') {
        this.question[index].name = event
      } else {
        this.questionEdit.name = event
      }

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    returnBack() {
      return this.$router.back()
    },
    setArchive() {
      this.selected = []

      this.filtered = this.archiveQuestion.filter(post => post.name.toLowerCase().includes(this.search.toLowerCase()))

      this.totalArchiveQuestion = 0

      return this.filtered
    },
    setSelected() {
      this.selected = []
    },
    getFile(data) {
      this.questionImage.push(data)
    },
    getFileEssay(data) {
      this.questionImageEssay.push(data)
    },
    async getChapter(uuid) {
      await this.$services.ApiServices.get('chapter', uuid).then(({ data }) => {
        this.chapter = data.data

        this.exercise = data.data.details
      })
      await this.getExercise()
    },
    showFormArchive() {
      if (this.exercise.uuid === '') {
        this.showSnackbar({
          text: 'Please, Add exercise first!!',
          color: 'error',
        })

        return
      }
      this.search = ''
      this.selected = []
      this.getQuestionArchive()
      this.isDisabledButton = false
      this.archiveModalDialog = true
    },
    handleCheckboxChange(archiveUuid, isSelected) {
      this.totalArchiveQuestion = isSelected.length
    },
    async getExercise(uuid) {
      await this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
        ({ data }) => {
          this.exercise = data.data
          this.exercise.deadline = data.data.deadline.slice(0, 10)
          this.exercise.publish_at = data.data.publish_at.slice(0, 10)
          this.exerciseMultiple = this.exercise.question.filter(item => item.is_essay === null)
          this.exerciseMultiple.sort((a, b) => a.number - b.number)
          this.exerciseEssay = this.exercise.question.filter(item => item.is_essay)
          this.exerciseEssay.sort((a, b) => a.number - b.number)
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update('chapter', this.chapter, this.chapter.uuid).then(
        ({ data }) => {
          this.chapter = data.data
        },

        err => console.error(err),
      )
      this.exercise.chapter_uuid = this.chapter.uuid
      this.exercise.title = this.chapter.name
      await this.$services.ApiServices.update('exercise', this.exercise, this.exercise.uuid).then(
        ({ data }) => {
          this.exercise = data.data
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.getChapter(this.$route.params.uuid)
    },
    getQuestion() {
      this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
        ({ data }) => {
          this.exercise = data.data.sort((a, b) => a.number - b.number)
        },
        err => console.error(err),
      )
    },
    async getQuestionArchive(search) {
      this.totalArchiveQuestion = 0
      if (typeof search === 'undefined') {
        search = ''
      }
      await this.$services.ApiServices.list('archivequestion', { search }).then(
        ({ data }) => {
          this.archiveQuestion = data.data.filter(item => item.is_essay === null)
        },
        err => console.error(err),
      )
    },
    async archiveExport() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('type', 'exercise')
      this.formData.append('type_uuid', this.exercise.uuid)
      this.selected.forEach(item => {
        this.formData.append('archive_uuid[]', item)
      })
      await this.$services.ApiServices.export('archivequestion', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Export archive to question successfully',
            color: 'success',
          })
          this.isDisabledButton = true
        },
        err => console.error(err),
      )
      await this.getExercise()
      this.numberQuestion = this.exerciseMultiple.length
      this.archiveModalDialog = false
      this.isLoadingButton = false
    },
    async addQuestion() {
      this.question.forEach(async (item, index) => {
        this.isLoadingButton = true
        item.type_uuid = this.exercise.uuid
        if (this.questionItem) {
          item.type_uuid = JSON.parse(localStorage.getItem('exercise')).uuid
        }
        item.number = this.exerciseMultiple.length + (index + 1)
        this.formData = new FormData()
        this.formData.append('type', item.type)
        this.formData.append('type_uuid', item.type_uuid)
        this.formData.append('number', item.number)
        this.formData.append('is_archive', item.archive ? 1 : null)
        this.formData.append('answer', item.answer)
        this.formData.append('point', item.point)
        this.formData.append('name', item.name)
        this.formData.append('discussion', item.discussion)
        this.formData.append('image', this.questionImage[index])
        if (typeof this.questionImage[index] === 'undefined') {
          this.formData.delete('image')
        }
        this.formData.append('choice_a', item.choice_a)
        this.formData.append('choice_b', item.choice_b)
        this.formData.append('choice_c', item.choice_c)
        this.formData.append('choice_d', item.choice_d)
        const tempImage = this.tempContent.split('"')
        tempImage.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
        const image = item.name.split('"')
        image.forEach(item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            const carIndex = this.tempImage.indexOf(img)
            this.tempImage.splice(carIndex, 1)

            this.tempImage.forEach(async item => {
              await this.deleteStorage({ file: item })
            })
          }
        })
        await this.$services.ApiServices.add('question', this.formData).then(
          async ({ data }) => {
            this.showSnackbar({
              text: 'Add question has successfully',
              color: 'success',
            })

            this.isHide = true
          },
          err => {
            this.isLoadingButton = false
          },
        )
        await this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
          ({ data }) => {
            this.exercise = data.data
          },
          err => console.error(err),
        )
        this.numberQuestion = this.exercise.question.length
        this.questions.splice(index, this.questions.length)
        if (this.questions.length < 1) {
          this.isHide = false
        }
        this.question = []
        this.answer = []
        this.isLoadingButton = false
      })
      const a = []

      if (typeof localStorage.getItem('question') !== 'string') {
        localStorage.setItem('question', JSON.stringify(this.question))
      } else {
        JSON.parse(localStorage.getItem('question')).forEach(item => {
          a.push(item)
        })
        this.question.forEach(item => {
          a.push(item)
        })
        localStorage.setItem('question', JSON.stringify(a))
      }
      this.getChapter(this.$route.params.uuid)
    },

    async addQuestionEssay() {
      this.isDisabledButton = false
      this.isLoadingButton = true
      this.questionEssay.forEach(async (item, index) => {
        item.type_uuid = this.exercise.uuid
        item.number = this.exerciseEssay.length + (index + 1)
        item.is_essay = 1
        this.formData = new FormData()
        this.formData.append('type', item.type)
        this.formData.append('type_uuid', item.type_uuid)
        this.formData.append('number', item.number)
        this.formData.append('name', item.name)
        this.formData.append('discussion', item.discussion)
        this.formData.append('is_essay', item.is_essay)
        this.formData.append('image', this.questionImageEssay[index])
        if (typeof this.questionImageEssay[index] === 'undefined') {
          this.formData.delete('image')
        }
        this.formData.append('is_archive', item.archive ? 1 : 0)
        await this.$services.ApiServices.add('question', this.formData).then(
          ({ data }) => {
            this.showSnackbar({
              text: 'Add question Essay has successfully',
              color: 'success',
            })
          },
          err => {
            this.isLoadingButton = false
          },
        )
        this.isHideEssay = false
        await this.getExercise()
        this.questionEssay = []
        this.numberQuestionEssay = this.exerciseEssay.length
        this.questionsEssay.splice(index, this.questionsEssay.length)
        if (this.questionsEssay.length < 1) {
          this.isHideEssay = false
        }
        this.isLoadingButton = false
        this.isDisabledButton = true
      })
    },
    addExerciseQuestion() {
      this.tempContent = ''
      this.tempImage = []
      this.questionImage = []
      this.isDisabledButton = false
      this.question = [
        ...this.question,
        {
          type: 'exercise',
          type_uuid: '',
          number: 0,
          name: '',
          choice_a: '',
          choice_b: '',
          choice_c: '',
          choice_d: '',
          discussion: '',
        },
      ]
      this.answer = [
        ...this.answer,
        {
          question_uuid: '',
          answer: '',
          point: 0,
        },
      ]
      this.question.number = this.question.length + 1
      this.questions.push(this.question)
      this.isHide = true
    },
    addExerciseQuestionEssay() {
      this.questionImageEssay = []
      this.isDisabledButton = false
      this.questionEssay = [
        ...this.questionEssay,
        {
          type: 'exercise',
          type_uuid: '',
          number: 0,
          name: '',
          discussion: '',
        },
      ]

      this.questionEssay.number = this.exerciseEssay.length + 1
      this.questionsEssay.push(this.questionEssay)
      this.isHideEssay = true
    },
    async editQuestion(uuid) {
      this.type = 'multipleChoice'
      this.tempContent = ''
      this.tempImage = []
      this.questionImage = []
      this.modalDialog = true
      this.questionEdit.uuid = uuid
      this.$services.ApiServices.get('question', uuid).then(({ data }) => {
        this.questionEdit = data.data
        const image = data.data.name.split('"')
        image.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
      })
    },
    async editQuestionEssay(uuid) {
      this.type = 'essay'
      this.tempContent = ''
      this.tempImage = []
      this.questionImage = []
      this.modalDialog = true
      this.questionEdit.uuid = uuid
      this.$services.ApiServices.get('question', uuid).then(({ data }) => {
        this.questionEdit = data.data
        const image = data.data.name.split('"')
        image.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
      })
    },
    async updateQuestion() {
      this.type = 'multipleChoice'
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append('choice_a', this.questionEdit.choice_a)
      this.formData.append('choice_b', this.questionEdit.choice_b)
      this.formData.append('choice_c', this.questionEdit.choice_c)
      this.formData.append('choice_d', this.questionEdit.choice_d)
      this.formData.append('discussion', this.questionEdit.discussion)
      this.formData.append('image', this.questionImage[0])
      if (typeof this.questionImage[0] === 'undefined') {
        this.formData.delete('image')
      }
      const image = this.questionEdit.name.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('question', this.formData, this.questionEdit.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update question has successfully',
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
        },
      )
      if (this.questionEdit.answer_key.answer !== '') {
        await this.$services.ApiServices.update(
          'answer',
          this.questionEdit.answer_key,
          this.questionEdit.answer_key.uuid,
        ).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => console.error(err),
        )
      }

      await this.getExercise()
      this.isLoadingButton = false
      this.modalDialog = false
      this.getQuestion()
    },
    async updateQuestionEssay() {
      this.type = 'essay'
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append('choice_a', this.questionEdit.choice_a)
      this.formData.append('choice_b', this.questionEdit.choice_b)
      this.formData.append('choice_c', this.questionEdit.choice_c)
      this.formData.append('choice_d', this.questionEdit.choice_d)
      this.formData.append('discussion', this.questionEdit.discussion)
      this.formData.append('image', this.questionImage[0])
      if (typeof this.questionImage[0] === 'undefined') {
        this.formData.delete('image')
      }
      const image = this.questionEdit.name.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('question', this.formData, this.questionEdit.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update question has successfully',
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
        },
      )
      if (this.questionEdit.answer_key.answer !== '') {
        await this.$services.ApiServices.update(
          'answer',
          this.questionEdit.answer_key,
          this.questionEdit.answer_key.uuid,
        ).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => console.error(err),
        )
      }

      await this.getExercise()
      this.isLoadingButton = false
      this.modalDialog = false
      this.getQuestion()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.question.uuid = uuid
    },
    async destroyQuestion() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('question', this.question.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
        let list
        const questionList = this.question.filter(el => {
          list = JSON.parse(JSON.stringify(el))

          return list.uuid !== data.data.uuid
        })
        this.question = JSON.parse(JSON.stringify(questionList))
      })
      await this.getExercise()
      this.isLoadingButton = false
      this.confirmDialog = false
      await this.getQuestion()
    },

    deleteExerciseQuestion(index) {
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''
      this.questions.splice(index, 1)
      if (this.questions.length < 1) {
        this.isHide = false
      }
    },
    deleteExerciseEssay(index) {
      this.questionsEssay.splice(index, 1)
      if (this.questionsEssay.length < 1) {
        this.isHideEssay = false
      }
    },
    autofillDeadline() {
      this.exercise.deadline = new Date().toISOString().substr(0, 10)
    },
    autofillPublishAt() {
      this.exercise.publish_at = new Date().toISOString().substr(0, 10)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 15px !important;
}
</style>
